<template>
  <div class="choiceData">
    <label @click="dlgVisible = true" class="choiceDataBtn">
      <slot name="button"> </slot>
    </label>
    <el-dialog
      class="choiceDataCon"
      :title="options.title"
      :visible.sync="dlgVisible"
      :append-to-body="true"
      width="60%"
    >
      <div class="selected-list">
        <div class="mainList">
          <div class="mainList_operation_search">
            <DeptCascader
              style="width: 30%"
              :placeholder="'请选择部门'"
              :deptList="deptList"
              v-model="parameter.deptId"
            />

            <el-input
              class="mainList_operation_search_Name"
              placeholder="姓名"
              clearable
              v-model="parameter.userName"
            ></el-input>
            <el-button type="primary" plain icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
          <div class="mainList_content">
            <div class="mainList_content_sub">
              <el-table
                ref="multipleTable"
                :border="true"
                :data="selectedList"
                tooltip-effect="dark"
                height="string"
                v-loading="selectedListLoad"
              >
                <slot name="tableColumn-left"></slot>

                <el-table-column align="center" prop="deptNames" label="部门"> </el-table-column>
                <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
                <el-table-column label="操作" width="120" align="center">
                  <template slot-scope="scope">
                    <el-button
                      v-if="options.type == 'radio'"
                      type="primary"
                      size="small"
                      @click="
                        $emit('selectDataChange', scope.row)
                        dlgVisible = false
                      "
                      :disabled="scope.row.Selected == 1"
                    >
                      <span v-if="scope.row.Selected == 1">已选</span>
                      <span v-else>选择</span></el-button
                    >
                    <el-checkbox
                      :disabled="scope.row.isCheckBox"
                      v-else
                      v-model="scope.row.shuttleBoxChecked"
                      @click.stop=""
                    ></el-checkbox>
                  </template>
                </el-table-column>

                <slot name="tableColumn"></slot>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="pageChangeHandler"
                :current-page="parameter.pageNow"
                :page-size="parameter.pageSize"
                prev-text="上一页"
                next-text="下一页"
                layout="total, prev, pager, next, slot, jumper"
                :total="parameter.total"
              >
                <span class="el-pagination__jump e_a_pagination">
                  <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
                  <span style="padding-top: 1px">条/页</span>
                </span>
              </el-pagination>
            </div>
          </div>
        </div>
      </div>

      <div v-if="options.type != 'radio'" slot="footer" class="dialog-footer">
        <el-button type="success" @click="save">确定</el-button>
        <el-button type="info" plain @click="dlgVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'selectDialog',
  components: {
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {
          title: '请选择',
          type: 'radio',
        }
      },
    },
    haveList: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      parameter: {
        deptId: '',
        userName: '',
        pageNow: 1,
        total: 1,
        pageSize: 50,
      },
      pageSize: 0,
      dlgVisible: false,
      selectedListLoad: false,
      selectedList: [],
      deptList: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      selectDeptList: state => state.business.selectDeptList,
    }),
  },
  watch: {
    selectedList: {
      deep: true,
      immediate: true,
      handler(val) {
        const haveListMap = {}
        this.haveList.forEach(n => {
          haveListMap[n] = true
        })
        val.forEach(v => {
          if (haveListMap[v.id]) {
            v.Selected = 1
          }
        })
      },
    },
    dlgVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.parameter.userName = ''
          this.parameter.deptId = ''
          this.parameter.pageNow = 1
          this.getSelectedList()
        }
      },
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    if (this.selectDeptList.length <= 0) {
      this.getDeptList()
    } else {
      this.deptList = this.selectDeptList
    }
  },
  mounted() {},
  methods: {
    //部门
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {})
    },

    handleReset() {
      this.parameter.userName = ''
      this.parameter.deptId = ''
      this.parameter.pageNow = 1
      this.getSelectedList()
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getSelectedList()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getSelectedList()
    },
    // 获取所有人员
    getSelectedList() {
      this.selectedListLoad = true
      this.$api.user
        .listStaff(this.parameter)
        .then(res => {
          this.selectedList = res.data?.records.deepClone()

          this.parameter.total = res.data?.total
          this.selectedListLoad = false
        })
        .catch(err => {
          console.log(err)
          this.selectedListLoad = false
        })
    },
    save() {
      arr.forEach(v => {
        v.isCheckBox = true
      })
      this.$emit('choiceDataChange', arr)
      this.dlgVisible = false
    },

    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getSelectedList()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
.mainList_operation_search_Name {
  width: 200px;
  margin: 0 10px 10px;
}
.mainList_operation_search {
  /deep/.el-input__validateIcon {
    display: none !important;
  }
}
</style>
